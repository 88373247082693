import React from 'react'

// --------------------------------------------------------------------------------

const InDeveloping: React.FC = () => {
    return (
        <main className='d-flex justify-content-center align-items-center'>
            <h2>В разработке</h2>
        </main>
    )
}

export default InDeveloping