import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CategoryCard from '../components/CategoryCard'
import ProductCard from '../components/ProductCard'
import { useGetCategoriesQuery, useGetHitProductQuery } from '../store/api/categoryApi'
import { BASE_URL } from '../api-config'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik'
import api from '../utils'
import { FetchApiError } from '@russh/fetch-api'
import { enqueueSnackbar } from 'notistack'
import {useNotification} from '../layouts/Notification/hook'

// --------------------------------------------------------------------------------

const Catalog: React.FC = () => {
    const { data } = useGetCategoriesQuery()
    const hitProduct = useGetHitProductQuery()
    const [setStatusNotification] =useNotification()

    const initialValues = { phone: '', name: '' }
    const handleSubmit = async (data: typeof initialValues, { setErrors }: FormikHelpers<typeof initialValues>) => {
        try {
            data.phone= data.phone?.replace(/ /g, '')?.replace(/-/g, '')?.replace('(', '')?.replace(')', '')
            await api.post('/requests', data)
            setStatusNotification({isShow:true, typeAlert:'good', message:'Заявка отправлена'})
        } catch (e) {
            let error = e as FetchApiError
            enqueueSnackbar(error.message)
            error.errors && setErrors(error.errors)
        }
    }

    return (
        <main>
            <Container>
                <section className='sec-9 mb-5'>
                    <Row className='gx-xxxl-5'>
                        <Col xl={9}>
                            <div className="title mb-4 mb-sm-5">
                                <h1 className="h2">Каталог</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row className='gx-xxxl-5 justify-content-center'>
                        <Col xl={9}>
                            <ul className='list-unstyled justify-content-center row row-cols-2 row-cols-md-3 gy-4 gy-md-0 gx-3 gx-sm-4 gx-xxxl-5'>
                                {data && data.map(cat =>
                                    <li key={cat.id}>
                                        <CategoryCard
                                            title={cat.name}
                                            img={BASE_URL + cat.image}
                                            link={`/catalog/category-${cat.id}`}
                                        />
                                    </li>
                                )}
                                <li className='d-md-none'>
                                    <figure className='category-card cap'>
                                        <img src={'/imgs/logo-colored.png'} alt="cap" />
                                    </figure>
                                </li>
                            </ul>
                        </Col>
                        {hitProduct.data &&
                            <Col xs={12} lg={8} xl={3} className='position-relative mt-5 mt-xl-0'>
                                <ProductCard data={hitProduct.data} />
                                <img src={'/imgs/icons/hit.svg'} alt="hit" className='hit' />
                            </Col>}
                    </Row>
                </section>

                <section className='sec-6 no-img mb-6'>
                    <Container className='position-relative'>
                        <Row className='gx-xxxl-5'>
                            <Col md={12} xxl={9}>
                                <div className="box">
                                    <h3>Оставьте заявку на подбор кондиционера</h3>
                                    <h5 className='mt-4 mt-lg-0'>И мы перезвоним в течение 20 минут, чтобы ответить на все ваши вопросы</h5>
                                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                                        <Form>
                                            <Field name='phone' type="tel" placeholder='+7 ___-___-__-__' />
                                            <ErrorMessage name='phone' component='div' className='error-message' />

                                            <Field name='name' type="text" placeholder='Имя' />
                                            <ErrorMessage name='name' component='div' className='error-message' />

                                            <button type='submit' className="btn-1 fw-6 px-3">Оставить заявку</button>

                                            <p>Нажимая кнопку «Отправить», вы даёте согласие на обработку персональных данных</p>
                                        </Form>
                                    </Formik>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>
        </main>
    )
}

export default Catalog