import React, {useEffect, useState} from 'react';
import './style.css'

export const NotificationContext = React.createContext(null);

const Notification = ({children}) => {
    const [notification, setNotification] = useState()
    useEffect(()=>{
        if (notification?.isShow)
            setTimeout(()=>setNotification({}), 2000)
    }, [notification])

    return (
        <>
            <div className={`${notification?.isShow ? 'alert' : ''} ${notification?.typeAlert ? notification?.typeAlert : ''}`}>
                <div>
                    {notification?.message}
                </div>
            </div>
            <NotificationContext.Provider value={[setNotification]}>
                {children}
            </NotificationContext.Provider>
        </>
    )
};

export default Notification;