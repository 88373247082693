import React from 'react'
import { Link } from 'react-router-dom'
import { useAddToCartMutation } from '../store/api/cartApi'
import { enqueueSnackbar } from 'notistack'
import { FetchApiError } from '@russh/fetch-api'
import { Product } from '../@types'
import { BASE_URL } from '../api-config'
import {useNotification} from '../layouts/Notification/hook'
// --------------------------------------------------------------------------------

interface ProductCardProps {
    data: Product
}

const ProductCard: React.FC<ProductCardProps> = ({ data }) => {

    const [addToCart] = useAddToCartMutation()
    const [setStatusNotification] =useNotification()

    const handleAddToCart = async () => {
        try {
            await addToCart({ productId: data.id })
            setStatusNotification({isShow:true, typeAlert:'good', message:'Товар добавлен в корзину'})
        } catch (e) {
            let error = e as FetchApiError
            setStatusNotification({isShow:true, typeAlert:'bad', message:'Ошибка добавления'})
            enqueueSnackbar(error.message)
        }
    }

    return (
        <figure className="product">
            <Link to={`/catalog/category-${data.categoryId}/product-${data.id}`}>
                <img src={BASE_URL + data.image} alt={data.name} />
            </Link>
            <figcaption>
                <h6 className='fw-4 mb-2 mb-sm-4'>
                    <Link to={`/catalog/category-${data.categoryId}/product-${data.id}`}>
                        {data.name}
                    </Link>
                </h6>
                <div className="product-bottom">
                    <div className='fw-5'>{data.price?.toLocaleString('ru')} ₽</div>
                    <button
                        type='button'
                        className='btn-1 fw-6 mt-2 mt-sm-0'
                        onClick={handleAddToCart}
                    >
                        Купить
                    </button>
                </div>
            </figcaption>
        </figure>
    )
}

export default ProductCard