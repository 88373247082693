import React, {useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import {GetContacts} from "../services/contacts";

const Contacts = () => {
    const [information, setInformation]= useState()
    useEffect(()=>{
        GetContacts().then(setInformation)
    }, [])

    console.log(information)
    return (
        <div>
            <main>
                <Container className='mt-4'>
                    <div className="title mb-4 mb-sm-5">
                        <h1 className="h2">Контакты</h1>
                    </div>
                    <section className='sec-4'>
                        <h2>{information?.legalStatus}</h2>
                        <ul className='list-unstyled mb-5'>

                            <li className='mb-3'> Казань: <a className={'tele'} href="tel:+78432521133"> +7 (843) 252 11 33</a></li>

                            <li className='mb-3'> Набережные челны, Нижнекамск:  <a className={'tele'} href={`tel:${information?.phone}`}>{information?.phone}</a></li>

                            <li className='mb-3'>Офис: <address className='black'>{information?.address}</address></li>

                            <li className='mb-3'><a href="/">{information?.email}</a></li>
                        </ul>
                        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aeeab577926a0cca395845685e0dd370667fc27bd45f0525fb47ba47198f54f6e&amp;source=constructor" width="100%" height="400" frameBorder="0"></iframe>
                    </section>
                </Container>
            </main>
        </div>
    );
};

export default Contacts;