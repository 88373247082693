import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { categoryApi } from './api/categoryApi'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { newsApi } from './api/newsApi'
import { reviewApi } from './api/reviewApi'
import { cartApi } from './api/cartApi'
import settingsReducer from './reducers/settingsSlice'
import { servicesApi } from './api/servicesApi'
import { searchApi } from './api/searchApi'
import { portfoliosApi } from "./api/portfolio";

// --------------------------------------------------------------------------------

const store = configureStore({
    reducer: {
        settings: settingsReducer,

        [categoryApi.reducerPath]: categoryApi.reducer,
        [servicesApi.reducerPath]: servicesApi.reducer,
        [reviewApi.reducerPath]: reviewApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [newsApi.reducerPath]: newsApi.reducer,
        [cartApi.reducerPath]: cartApi.reducer,
        [portfoliosApi.reducerPath]: portfoliosApi.reducer
    },
    middleware: (gDM) =>
        gDM().concat([
            categoryApi.middleware,
            servicesApi.middleware,
            searchApi.middleware,
            reviewApi.middleware,
            newsApi.middleware,
            cartApi.middleware,
            portfoliosApi.middleware
        ]),
})

setupListeners(store.dispatch)

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type RootStore = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store