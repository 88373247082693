import React, { useState } from 'react'
import api from '../utils'
import { enqueueSnackbar } from 'notistack'
import { FetchApiError } from '@russh/fetch-api'
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'

// --------------------------------------------------------------------------------

const Quiz: React.FC = () => {
  const [page, setPage] = useState(1)
  const count = 4
  const arr = Array.from({ length: count }, (v, i) => i + 1)

  const isActive = (num: number) => (num === page ? 'active' : '')

  const initialValues = {
    phone: '',
    name: '',
    place: '',
    area: '',
    type: ''
  }
  const handleSubmit = async (data: typeof initialValues, { setErrors }: FormikHelpers<typeof initialValues>) => {
    try {
      await api.post('/requests', data)
      enqueueSnackbar('Заявка отправлена')
    } catch (e) {
      let error = e as FetchApiError
      enqueueSnackbar(error.message)
      error.errors && setErrors(error.errors)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ values, setFieldValue }: FormikProps<typeof initialValues>) =>
        <Form className='quiz'>
          <div className="quiz-pagination">
            <h5>Вопрос {page} из {count}</h5>
            <div className="quiz-pagination-bullets">
              {
                arr.map(obj => <div key={obj} className={isActive(obj)}></div>)
              }
            </div>
          </div>

          {
            (page === 1) &&
            <>
              <div className="quiz-top">
                <h3>Куда вы планируете поставить кондиционер?</h3>
              </div>
              <div className="quiz-main">
                <button type='button' className='btn-6 me-4' disabled>Назад</button>
                <ul className="flex-1 list-unstyled row row-cols-3 gx-2 gx-sm-3 gx-xxl-4 gx-xxxl-5">
                  <li>
                    <label className='variant'>
                      <input type="radio" name='place' hidden onChange={() => setFieldValue('place', 'Квартира')} />
                      <div className='variant-visual'>
                        <h3 className='text-center'>Квартира</h3>
                        <img src={'/imgs/apartment.jpg'} alt="Квартира" />
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant'>
                      <input type="radio" name='place' hidden onChange={() => setFieldValue('place', 'Дом')} />
                      <div className='variant-visual'>
                        <h3 className='text-center'>Дом</h3>
                        <img src={'/imgs/house.jpg'} alt="Дом" />
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant'>
                      <input type="radio" name='place' hidden onChange={() => setFieldValue('place', 'Офис')} />
                      <div className='variant-visual'>
                        <h3 className='text-center'>Офис</h3>
                        <img src={'/imgs/office.jpg'} alt="Офис" />
                      </div>
                    </label>
                  </li>
                </ul>
                <button type='button' className='btn-1 ms-4' onClick={() => setPage(2)}>Далее</button>
              </div>
            </>
          }

          {
            (page === 2) &&
            <>
              <div className="quiz-top">
                <h3>Выберите площадь помещения</h3>
              </div>
              <div className="quiz-main">
                <button type='button' className='btn-6 me-4' onClick={() => setPage(1)}>Назад</button>
                <ul className="flex-1 list-unstyled row row-cols-2 row-cols-sm-3 g-3 gx-xxl-4 gx-xxxl-5 gy-xxl-4">
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'до 20 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>до 20 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'до 30 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>до 30 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'до 40 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>до 40 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'до 50 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>до 50 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'до 60 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>до 60 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'до 70 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>до 70 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'до 80 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>до 80 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'до 100 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>до 100 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant-2'>
                      <input type="radio" name='area' hidden onChange={() => setFieldValue('area', 'свыше 100 кв.м.')} />
                      <div className='variant-2-visual'>
                        <h5 className='text-center mb-0'>свыше 100 кв.м.</h5>
                      </div>
                    </label>
                  </li>
                </ul>
                <button type='button' className='btn-1 ms-4' onClick={() => setPage(3)}>Далее</button>
              </div>
            </>
          }

          {
            (page === 3) &&
            <>
              <div className="quiz-top">
                <h3>Какой тип кондиционера хотите установить?</h3>
              </div>
              <div className="quiz-main">
                <button type='button' className='btn-6 me-4' onClick={() => setPage(2)}>Назад</button>
                <ul className="flex-1 list-unstyled row row-cols-2 row-cols-sm-3 gx-3 gx-xxl-4 gx-xxxl-5 gy-4">
                  <li>
                    <label className='variant'>
                      <input type="radio" name='type' hidden onChange={() => setFieldValue('type', 'Настенная сплит-система')} />
                      <div className='variant-visual'>
                        <h3 className='text-center'>Настенная <br />сплит-система</h3>
                        <img src={'/imgs/conditioner1.png'} className='object-fit-contain' alt="Настенная сплит-система" />
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant'>
                      <input type="radio" name='type' hidden onChange={() => setFieldValue('type', 'Мультисплит система')} />
                      <div className='variant-visual'>
                        <h3 className='text-center'>Мультисплит <br />система</h3>
                        <img src={'/imgs/conditioner2.png'} className='object-fit-contain' alt="Мультисплит система" />
                      </div>
                    </label>
                  </li>
                  <li>
                    <label className='variant'>
                      <input type="radio" name='type' hidden onChange={() => setFieldValue('type', 'Кассетный кондиционер')} />
                      <div className='variant-visual'>
                        <h3 className='text-center'>Кассетный <br />кондиционер</h3>
                        <img src={'/imgs/conditioner3.png'} className='object-fit-contain' alt="Кассетный кондиционер" />
                      </div>
                    </label>
                  </li>
                </ul>
                <button type='button' className='btn-1 ms-4' onClick={() => setPage(4)}>Далее</button>
              </div>
            </>
          }

          {
            (page === 4) &&
            <>
              <div className="quiz-top">
                <h3>Скидка 10% уже ждет вас</h3>
              </div>
              <div className="quiz-main last">
                <button type='button' className='btn-6' onClick={() => setPage(1)}>Начать сначала</button>
                <div className='content'>
                  <h3>Оставьте контактные данные и менеджер перезвонит вам </h3>
                  <div className="d-md-flex fs-12 mb-2">

                    <Field type="tel" name='phone' placeholder='+7 ___-___-__-__' className='mb-3 mb-md-0 me-md-4 me-lg-5' />
                    <Field type="text" name='name' placeholder='Имя' className='mb-3 mb-md-0 me-md-4 me-lg-5' />

                    <button type='submit' className="btn-1 fw-6 mx-auto mx-md-0">Отправить</button>
                  </div>
                  <p>Нажимая кнопку «Отправить», вы даёте согласие на обработку персональных данных</p>
                </div>
              </div>
            </>
          }
        </Form>}
    </Formik>
  )
}

export default Quiz