import { createApi } from '@reduxjs/toolkit/query/react'
import api from '../../utils'
// @types
import { ApiGetCategory, ApiGetCategoryProduct, ApiGetCategoryProducts } from '../../@types/api'
import { Product } from '../../@types'

// --------------------------------------------------------------------------------

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: api.baseQuery,
    keepUnusedDataFor: 60 * 60,
    tagTypes: ['Categories', 'Category', 'Product'],
    endpoints: (builder) => ({

        getCategories: builder.query<ApiGetCategory[], void>({
            query: () => ({ url: `/categories` }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Categories' as const, id })), 'Categories']
                    : ['Categories']
        }),

        getCategory: builder.query<ApiGetCategory, string | undefined>({
            query: (categoryId) => ({ url: `/categories/${categoryId}` }),
            providesTags: (result) =>
                result ? [{ type: 'Category' as const, id: result.id }] : ['Category']
        }),

        getCategoryProducts: builder.query<ApiGetCategoryProducts, { categoryId: string | number | undefined, params: Record<string, string> }>({
            query: ({ categoryId, params }) => ({ url: `/categories/${categoryId ?? 0}/products?${new URLSearchParams(params)}` }),
            providesTags: (result) =>
                result
                    ? [...result.products.map(({ id }) => ({ type: 'Product' as const, id })), 'Product']
                    : ['Product']
        }),

        getCategoryBestDeals: builder.query<ApiGetCategoryProduct[], { categoryId: string | number | undefined }>({
            query: ({ categoryId }) => ({ url: `/categories/${categoryId ?? 0}/best-deals` }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Product' as const, id })), 'Product']
                    : ['Product']
        }),

        getCategoryProduct: builder.query<ApiGetCategoryProduct, { categoryId: string | undefined, id: string | undefined }>({
            query: ({ categoryId, id }) => ({ url: `/categories/${categoryId}/products/${id}` }),
            providesTags: (result) =>
                result ? [{ type: 'Product' as const, id: result.id }] : ['Product']
        }),

        getHomePageProducts: builder.query<ApiGetCategoryProduct[], void>({
            query: () => ({ url: `/products/home-page` }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Product' as const, id })), 'Product']
                    : ['Product']
        }),

        getHitProduct: builder.query<Product, void>({
            query: () => ({ url: `/products/hit` }),
            providesTags: (result) =>
                result ? [{ type: 'Product' as const, id: result.id }] : ['Product']
        }),

    }),
})

export const {
    useGetCategoryQuery,
    useGetCategoriesQuery,
    useGetHitProductQuery,
    useGetCategoryProductQuery,
    useGetCategoryProductsQuery,
    useGetHomePageProductsQuery,
    useGetCategoryBestDealsQuery,
} = categoryApi