import React from 'react'
import { IoChevronBack, IoChevronForward, IoEllipsisHorizontal } from 'react-icons/io5'
import { Pagination } from '../@types/pagination'

interface NavPaginationProps {
    className?: string,
    pages?: Pagination | undefined,
    setPage?: React.Dispatch<React.SetStateAction<number>>
}

const NavPagination: React.FC<NavPaginationProps> = ({ className, pages, setPage }) => {

    if (!(pages && setPage)) return null
    if (pages.last < 2) return null

    return (
        <nav className={'pagination ' + className}>
            <ul>
                {pages.last > 3 &&
                    <li>
                        <a onClick={() => setPage(pages.previous ?? pages.first)}>
                            <IoChevronBack />
                        </a>
                    </li>}
                {pages.current - pages.first > 1 &&
                    <li>
                        <a onClick={() => setPage(pages.first)}>
                            {pages.first}
                        </a>
                    </li>}
                {pages.current - pages.first > 2 &&
                    <li>
                        <a>
                            <IoEllipsisHorizontal />
                        </a>
                    </li>}
                {pages.previous &&
                    <li>
                        <a onClick={() => setPage(pages.previous)} >
                            {pages.previous}
                        </a>
                    </li>}
                <li className="active">
                    <a onClick={() => setPage(pages.current)} >
                        {pages.current}
                    </a>
                </li>
                {pages.next &&
                    <li>
                        <a onClick={() => setPage(pages.next)} >
                            {pages.next}
                        </a>
                    </li>}
                {pages.last - pages.current > 2 &&
                    <li>
                        <a className='user-select-none'>
                            <IoEllipsisHorizontal />
                        </a>
                    </li>}
                {pages.last - pages.current > 1 &&
                    <li>
                        <a onClick={() => setPage(pages.last)} >
                            {pages.last}
                        </a>
                    </li>}
                {pages.last > 3 &&
                    <li>
                        <a onClick={() => setPage(pages.next ?? pages.last)}>
                            <IoChevronForward />
                        </a>
                    </li>}
            </ul>
        </nav>
    )
}

export default NavPagination