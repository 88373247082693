import React from 'react'
import { Link } from 'react-router-dom'
import { BsChevronRight } from "react-icons/bs"
import { News } from '../@types'
import { BASE_URL } from '../api-config'

// --------------------------------------------------------------------------------

interface NewsCardProps {
    data: News
}

const NewsCard: React.FC<NewsCardProps> = ({ data }) => {

    return (
        <figure className='news'>
            <figcaption>
                <h6 className='flex-1'>{data.title}</h6>
                <Link to={`/blog/article-${data.id}`} className='stretched-link'>
                    <BsChevronRight />
                </Link>
            </figcaption>
            <img src={BASE_URL + data.image} alt={data.title ?? ''} />
        </figure>
    )
}

export default NewsCard