import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// --------------------------------------------------------------------------------

interface City {
    name: string
    phone: string
}

const storedCity = localStorage.getItem('city')

const initialState: {
    city: City
} = {
    city: storedCity !== null
        ? JSON.parse(storedCity)
        : { name: 'Казань', phone: '+7 843 252 11 33' },
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setCity: (state, action: PayloadAction<City>) => {
            state.city = action.payload
            localStorage.setItem('city', JSON.stringify(action.payload))
        },
    },
})

export const { setCity, } = settingsSlice.actions

export default settingsSlice.reducer
