import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link, NavLink } from 'react-router-dom'
import useIsMobile from '../hooks/useIsMobile'
import { HiOutlineHome, HiOutlineSquares2X2, HiOutlineShoppingCart, HiOutlinePhone, HiOutlineUser } from "react-icons/hi2"
import Devs from './utils/Devs'
import { useGetCategoriesQuery } from '../store/api/categoryApi'

// --------------------------------------------------------------------------------

const Footer: React.FC = () => {
  const { isMobile } = useIsMobile('991px')
  const { data } = useGetCategoriesQuery()

  if (isMobile) {
    return (<footer className='mobile'>
      <Container className='h-100'>
        <nav>
          <ul>
            <li>
              <NavLink to="/" end>
                <HiOutlineHome />
                <span>Главная</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/catalog">
                <HiOutlineSquares2X2 />
                <span>Каталог</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/cart">
                <HiOutlineShoppingCart />
                <span>Корзина</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/contacts">
                <HiOutlinePhone />
                <span>Контакты</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/profile">
                <HiOutlineUser />
                <span>Профиль</span>
              </NavLink>
            </li> */}
          </ul>
        </nav>
      </Container>
    </footer>)
  } else {
    return (
      <footer className='desktop'>
        <Container className='h-100 d-flex justify-content-between align-items-center'>
          <div>
            <ul className='mb-4'>
              <li>
                <Link to="/">Главная</Link>
              </li>
              <li>
                <Link to="/catalog">Каталог</Link>
              </li>
              <li>
                <Link to="/services">Услуги</Link>
              </li>
              <li>
                <Link to="/payment">Доставка и оплата</Link>
              </li>
              <li>
                <Link to="/portfolio">Примеры работ</Link>
              </li>
              <li>
                <Link to="/about">О компании</Link>
              </li>
              <li>
                <Link to="/contacts">Контакты</Link>
              </li>
            </ul>
            <ul className='mb-4'>
              {data && data.map(category =>
                <li key={`footer-category-${category.id}`}>
                  <Link to={`/catalog/category-${category.id}`}>{category.name}</Link>
                </li>
              )}
            </ul>
            <div><Link to="/policy" className='bb-05'>Политика конфиденциальности</Link></div>
          </div>
          <Devs />
        </Container>
      </footer>
    )
  }
}

export default Footer