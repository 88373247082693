import { createApi } from '@reduxjs/toolkit/query/react'
import { ApiGetCategoryProducts } from '../../@types/api'
import api from '../../utils'
import { PaginationQueryParams } from '../../@types/pagination'

// --------------------------------------------------------------------------------

export const searchApi = createApi({
    reducerPath: 'searchApi',
    baseQuery: api.baseQuery,
    tagTypes: ['Product'],
    endpoints: (builder) => ({

        searchProduct: builder.query<ApiGetCategoryProducts, { search: string | undefined, params: PaginationQueryParams }>({
            query: ({ search, params }) => ({ url: `/products/search/${search}?${new URLSearchParams(Object.entries(params))}` }),
            providesTags: (result) =>
                result
                    ? [...result.products.map(({ id }) => ({ type: 'Product' as const, id })), 'Product']
                    : ['Product']
        }),

    }),
})

export const {
    useSearchProductQuery
} = searchApi