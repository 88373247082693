import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProductCard from '../components/ProductCard'
import NavPagination from '../components/NavPagination'
import { Link, useLocation } from 'react-router-dom'
import { useSearchProductQuery } from '../store/api/searchApi'
import { Sort } from '../@types/pagination'

// --------------------------------------------------------------------------------

const SearchPage: React.FC = () => {
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState(Sort.asc)
    const { state } = useLocation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [page])

    const { data } = useSearchProductQuery({ search: state, params: { page, sort } })

    return (
        <main>
            <Container>
                <section className='sec-12 mb-6'>
                    <h1 className='mb-4'>Результат поиска по запросу “{state}”</h1>

                    <Row className='gx-xxxl-5 mt-4 mt-sm-5'>
                        {data?.products && data.products.length > 0
                            ?
                            <Col lg={12}>
                                <div className='d-flex align-items-center justify-content-end flex-wrap flex-lg-nowrap mb-5'>

                                    <select defaultValue="asc" className='btn-2' onChange={e => setSort(e.target.value as Sort)}>
                                        <option value="asc">Сначала дешёвые</option>
                                        <option value="desc">Сначала дорогие</option>
                                    </select>
                                </div>

                                <ul className='list-unstyled row row-cols-2 row-cols-md-3 gx-2 gx-sm-3 gx-xl-4 gx-xxxl-5 gy-4 gy-sm-5'>
                                    {data.products.map(product =>
                                        <li key={'product-' + product.id}>
                                            <ProductCard data={product} />
                                        </li>
                                    )}
                                </ul>

                                <NavPagination className='nav-pagination' pages={data?.pages} setPage={setPage} />
                            </Col>
                            :
                            <Col lg={12}>
                                <div className="nothing-found mb-4 mb-md-5">
                                    <h2 className='mb-0'>Попробуйте изменить запрос или найти товар в каталоге</h2>
                                    <Link to='/catalog' className='btn-3 me-lg-5'>Перейти в каталог</Link>
                                </div>
                            </Col>}
                    </Row>
                </section>
            </Container>
        </main>
    )
}

export default SearchPage    