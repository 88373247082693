import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Offcanvas from 'react-bootstrap/Offcanvas'
import ShoppingCart from './svg/ShoppingCart'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Search from './svg/Search'
import { HiOutlineDevicePhoneMobile, HiXMark, HiBars3 } from "react-icons/hi2"
import useIsMobile from '../hooks/useIsMobile'
import Devs from './utils/Devs'
import Offer from './Offer'
import { useGetCartQuery } from '../store/api/cartApi'
import { useAppDispatch, useAppSelector } from '../store'
import { setCity } from '../store/reducers/settingsSlice'
import { Field, Form, Formik } from 'formik'

// --------------------------------------------------------------------------------
const cities = [
    { name: 'Казань', phone: '+7 843 252 11 33' },
    { name: 'Набережные Челны', phone: '+7 (8555) 40-43-44' },
    { name: 'Нижнекамск', phone: '+7 (8555) 40-43-44' },
]

const Header: React.FC = () => {
    const { isMobile } = useIsMobile('991px')
    const cart = useGetCartQuery()
    const city = useAppSelector(state => state.settings.city)
    const dispatch = useAppDispatch()
    const nav = useNavigate()

    const [isShowSearch, setIsShowSearch] = useState(false)
    const handleCloseSearch = () => setIsShowSearch(false)
    const handleShowSearch = () => setIsShowSearch(true)

    const [isShowMenu, setIsShowMenu] = useState(false)
    const handleCloseMenu = () => setIsShowMenu(false)
    const handleShowMenu = () => setIsShowMenu(true)

    const initialValues = { search: '' }
    const handleSubmit = (values: typeof initialValues) => {
        nav('/search', { state: values.search })
    }
    
    return (
        <>
            <header>
                <Container className='h-100 d-flex justify-content-between align-items-center'>
                    <div className="d-flex align-items-center">
                        <Link to="/" className='logo'>
                            {
                                (isMobile)
                                    ? <img src={'/imgs/logo-colored.png'} alt="logotip" />
                                    : <img src={'/imgs/logo.png'} alt="logotip" />
                            }
                        </Link>
                        <Dropdown className='d-none d-lg-block p-3'>
                            <Dropdown.Toggle variant={'none'}>
                                {city.name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {cities.map(el =>
                                    el.name !== city.name &&
                                    <Dropdown.Item
                                        key={`city-${el.name}`}
                                        onClick={() => dispatch(setCity(el))}
                                    >
                                        {el.name}
                                    </Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    {
                        (!isMobile) &&
                        <ul className='list-unstyled d-flex menu'>
                            <li><NavLink to="/catalog">Каталог</NavLink></li>
                            <li><NavLink to="/services">Услуги</NavLink></li>
                            <li><NavLink to="/portfolio">Примеры работ</NavLink></li>
                            <li><NavLink to="/about">О компании</NavLink></li>
                            {/*<li><NavLink to="/contacts">Контакты</NavLink></li>*/}
                        </ul>
                    }

                    <div className="d-flex align-items-center p-3">
                        <button type='button' className='search-icon d-xl-none' onClick={handleShowSearch}>
                            <Search />
                        </button>
                        <Offcanvas show={isShowSearch} onHide={handleCloseSearch} placement={'top'} responsive="xl" className="offcanvas-search">
                            <Offcanvas.Body>
                                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                                    <Form className='form-search'>
                                        <Field name='search' type="search" placeholder='Поиск' />
                                        <button type='submit'>
                                            <Search />
                                        </button>
                                    </Form>
                                </Formik>
                                <button type='button' className='close d-xl-none' onClick={handleCloseSearch}>
                                    <HiXMark className='fs-15' />
                                </button>
                            </Offcanvas.Body>
                        </Offcanvas>

                        <a href={"tel:"+city.phone} className='phone ms-4 ms-xxxl-5'>
                            <HiOutlineDevicePhoneMobile className='fs-13 d-xl-none' />
                            <span className='d-none d-xl-inline'>{city.phone}</span>
                        </a>
                        <Link to='cart' className='cart ms-4 ms-xxxl-5'>
                            <ShoppingCart />
                            {cart.data && cart.data.length > 0 && <div className='cart-badge'>{cart.data.length}</div>}
                        </Link>

                        <button type='button' className='btn-menu' onClick={(isShowMenu) ? handleCloseMenu : handleShowMenu}>
                            {
                                (isShowMenu)
                                    ? <HiXMark />
                                    : <HiBars3 />
                            }
                        </button>
                    </div>
                </Container>
            </header>

            <Offcanvas show={isShowMenu} onHide={handleCloseMenu} className="offcanvas-menu" placement={'end'}>
                <Offcanvas.Body>
                    <Container>
                        <Dropdown className=' d-lg-block p-3'>
                            <Dropdown.Toggle variant={'none'}>
                                {city.name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {cities.map(el =>
                                    el.name !== city.name &&
                                    <Dropdown.Item
                                        key={`city-${el.name}`}
                                        onClick={() => dispatch(setCity(el))}
                                    >
                                        {el.name}
                                    </Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Offer onClick={handleCloseMenu} />

                        <nav onClick={handleCloseMenu}>
                            <ul className='list-unstyled'>
                                <li><Link to="/">Главная</Link></li>
                                <li><Link to="/catalog">Каталог</Link></li>
                                <li><Link to="/services">Услуги</Link></li>
                                <li><Link to="/portfolio">Примеры работ</Link></li>
                                <li><Link to="/about">О компании</Link></li>
                                <li><Link to="/delivery">Оплата и доставка</Link></li>
                            </ul>
                        </nav>
                        <Devs className={'mx-auto mt-4'} />
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Header