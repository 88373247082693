import React from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { SnackbarProvider } from 'notistack'
import Notification from './Notification/Notification'
// --------------------------------------------------------------------------------

const AppLayout = () => {
  return (
    <>
        <Notification>
            <SnackbarProvider autoHideDuration={5000} />
            <ScrollRestoration />
            <Header />
            <Outlet />
            <Footer />
        </Notification>
    </>
  )
}

export default AppLayout