type ApiRoutesTypes = {
    [key: string]: string
}

// const BASE_URL: string = 'http://localhost:5000'
const BASE_URL: string = 'https://api.xn--b1afkftofec.xn--p1ai'

const apiRoutes: ApiRoutesTypes = {
    //auth
    SIGNUP: '/auth/signup',
    SIGNUP_VERIFY: '/auth/signup/verify',
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REFRESH_TOKEN: '/auth/refresh',

    FORGOT_PASSWORD_SEND_CODE: '/auth/reset-password/send-code',
    FORGOT_PASSWORD_VERIFY: '/auth/reset-password/verify',
    FORGOT_PASSWORD: '/auth/reset-password',

    // users
    USERS: '/users',
    UPDATE_USER: '/users/withProfile',
    UPDATE_USER_SETTINGS: '/users/settings',

    // portfolio
    GET_PORTFOLIO:'portfolio',

    // contacts
    CONTACTS:'settings'
}

export { apiRoutes, BASE_URL }
