import React from 'react'

// --------------------------------------------------------------------------------

interface OfferProps {
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const Offer: React.FC<OfferProps> = ({ className, onClick }) => {
  return (
    <div className={"offer " + className} onClick={onClick}>
      <div className="text">
        <h3>Вместе выгоднее</h3>
        <p>Закажите оборудование и&nbsp;получите скидку&nbsp;50% на&nbsp;его установку</p>
        <button type='button' className='btn-4 ms-auto mt-3'>Подробнее</button>
      </div>
      <img src={'/imgs/bg/human-full.png'} alt="HumanFull" />
    </div>
  )
}

export default Offer