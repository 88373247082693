import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { BASE_URL, apiRoutes } from '../api-config'
import React, { useEffect, useState } from 'react'
import FetchApi from '@russh/fetch-api'

// --------------------------------------------------------------------------------

export const useApiInit = () => {
    const [apiReady, setApiReady] = useState(false)

    useEffect(() => {
        FingerprintJS.load()
            .then(fp => fp.get())
            .then(fp => api.setAuthHeader(fp.visitorId))
            .then(() => setApiReady(true))
    }, [])

    return apiReady

}

const api = new FetchApi(BASE_URL, apiRoutes.REFRESH_TOKEN)

export default api