import React from 'react'
import AppRouter from './routes/AppRouter'
import { useApiInit } from './utils'

// --------------------------------------------------------------------------------

function App() {
    const apiReady = useApiInit()

    return apiReady ? <AppRouter /> : <></>
}

export default App