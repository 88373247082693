import React from 'react'
import Container from 'react-bootstrap/Container'
import Breadcrumbs from '../components/Breadcrumbs'
import { Link, useParams } from 'react-router-dom'
import { useGetServiceQuery } from '../store/api/servicesApi'

// --------------------------------------------------------------------------------

const ServicePage: React.FC = () => {
    const { service } = useParams()
    const { data } = useGetServiceQuery(service?.split('-')[1], { skip: !service })

    const markup = { __html: data?.description ?? '' }

    return (
        <main>
            <Container>
                <Breadcrumbs title={data?.name ?? ''} />

                <article className='mb-6'>
                    <div className="d-flex justify-content-between align-items-center mb-4 mb-md-5">
                        <h1 className="h3 mb-0">{data?.name}</h1>
                        <Link to="/services" className='btn-1'>Назад</Link>
                    </div>
                    <div className="text">
                        <p dangerouslySetInnerHTML={markup} />
                    </div>
                </article>

            </Container>
        </main>
    )
}

export default ServicePage