import React, {FC} from 'react';
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BASE_URL} from "../api-config";
import {IPortfolio} from "../@types";

const PortfolioCard:FC<IPortfolio> = (props) => {
    const {id, image, title, subtitle} = props
    return (
        <article className={'my-2'}>
            <Row className='gx-2 gx-sm-3 gx-md-4'>
                <Col xs={6} md={4} lg={3}>
                    <img style={{width:'100%', height:'auto', borderRadius:20, aspectRatio:'2/2'}} src={BASE_URL+image} alt={title} />
                </Col>
                <Col xs={6} md={8} lg={9}>
                    <h2>{title}</h2>
                    {
                        <ul className='mt-3 mt-sm-4'>
                            <li>{subtitle}</li>
                        </ul>
                    }
                    <Link to={`${id}`} className='btn-2 my-4' >Подробнее</Link>
                </Col>
            </Row>
        </article>
    );

};

export default PortfolioCard;