import { createApi } from '@reduxjs/toolkit/query/react'
import api from '../../utils'
import { Service } from '../../@types'

// --------------------------------------------------------------------------------

export const servicesApi = createApi({
    reducerPath: 'servicesApi',
    baseQuery: api.baseQuery,
    keepUnusedDataFor: 60 * 60,
    tagTypes: ['Service'],
    endpoints: (builder) => ({

        getServices: builder.query<Service[], void>({
            query: () => ({ url: `/services` }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Service' as const, id })), 'Service']
                    : ['Service']
        }),

        getService: builder.query<Service, string | undefined>({
            query: (id) => ({ url: `/services/${id}` }),
            providesTags: (result) =>
                result ? [{ type: 'Service' as const, id: result.id }] : ['Service']
        }),

    }),
})

export const {
    useGetServiceQuery,
    useGetServicesQuery
} = servicesApi