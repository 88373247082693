import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumbs from '../components/Breadcrumbs'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/free-mode"
import "swiper/css/thumbs"
import 'swiper/css/pagination'
import SwiperCore, { Thumbs, FreeMode } from 'swiper'


import { HiPlus } from "react-icons/hi2"
import ProductCard from '../components/ProductCard'
import { useGetCategoryProductQuery } from '../store/api/categoryApi'
import { useParams } from 'react-router-dom'
import { BASE_URL } from '../api-config'
import { useAddToCartMutation } from '../store/api/cartApi'
import { FetchApiError } from '@russh/fetch-api'
import { enqueueSnackbar } from 'notistack'

// --------------------------------------------------------------------------------

const Product: React.FC = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>()
    const [tab, setTab] = useState(1)
    const { category, product } = useParams()

    const { data } = useGetCategoryProductQuery({ categoryId: category?.split('-')[1], id: product?.split('-')[1] })

    const [addToCart] = useAddToCartMutation()

    const handleAddToCart = async () => {
        if (!data) return
        try {
            await addToCart({ productId: data.id })
        } catch (e) {
            let error = e as FetchApiError
            enqueueSnackbar(error.message)
        }
    }

    console.log(data)

    return (
        <main>
            <Container>
                <Breadcrumbs title={data?.name ?? ''} />
                <section className='sec-13 mb-6'>
                    <div className="product-grid">
                        <div className="area-1">
                            <h1 className='h2'>{data?.name}</h1>
                            <p className="gray fw-5 mb-4">Артикул: {data?.vendorCode}</p>
                            <div className='text'>
                                <h3>Характеристики</h3>
                                <ul>
                                    {data?.parameters && data.parameters.map(param =>
                                        <li key={'param-' + param.ProductParamOption.name}>
                                            <span className='fw-6'>{param.ProductParamOption.productParam.name}</span>{' '}
                                            {param.ProductParamOption.name}
                                        </li>)}
                                </ul>
                            </div>
                        </div>
                        <div className="area-2">
                            <Swiper
                                direction="vertical"
                                loop={true}
                                spaceBetween={20}
                                slidesPerView={'auto'}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Thumbs]}
                                className="thumbslides"
                                onSwiper={setThumbsSwiper}
                            >
                                <SwiperSlide>
                                    <img src={BASE_URL + data?.image} alt={data?.name ?? ''} />
                                </SwiperSlide>
                                {data?.images && data.images.map((image, index) =>
                                    <SwiperSlide key={'image-swiper-vertical-' + index}>
                                        <img src={BASE_URL + image} alt={data?.name ?? ''} />
                                    </SwiperSlide>)}
                            </Swiper>

                            <Swiper
                                loop={true}
                                spaceBetween={10}
                                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                modules={[FreeMode, Thumbs]}
                                className="mainslides"
                                breakpoints={{
                                    768: {
                                        spaceBetween: 20
                                    }
                                }}
                            >
                                <SwiperSlide>
                                    <img src={BASE_URL + data?.image} alt={data?.name ?? ''} />
                                </SwiperSlide>
                                {data?.images && data.images.map((image, index) =>
                                    <SwiperSlide key={'image-swiper-' + index}>
                                        <img src={BASE_URL + image} alt={data?.name ?? ''} />
                                    </SwiperSlide>)}
                            </Swiper>
                        </div>
                        <div className="area-3">
                            <Row>
                                <Col xl={11} xxl={10} xxxl={9}>
                                    {/*<h5>Охлаждение</h5>*/}
                                    {/*<ul className="params-list">*/}
                                    {/*    <li>*/}
                                    {/*        <label className='pill'>*/}
                                    {/*            <input type="radio" name="param" hidden />*/}
                                    {/*            <div>12000 БТЕ</div>*/}
                                    {/*        </label>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <label className='pill'>*/}
                                    {/*            <input type="radio" name="param" hidden />*/}
                                    {/*            <div>12000 БТЕ</div>*/}
                                    {/*        </label>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*        <label className='pill'>*/}
                                    {/*            <input type="radio" name="param" hidden />*/}
                                    {/*            <div>12000 БТЕ</div>*/}
                                    {/*        </label>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    <div className="price-box">
                                        <div>
                                            <p className='price'>{data?.price.toLocaleString('ru')} ₽</p>
                                            <p className='info'>цена за одну штуку</p>
                                        </div>
                                        <button type='button' className='btn-3' onClick={handleAddToCart}>В корзину</button>
                                    </div>

                                    {/*<p className='fs-12 mb-3'>В комплекте дешевле:</p>*/}
                                    {/*<div className="set">*/}
                                    {/*    <div className="set-item">*/}
                                    {/*        <img src={BASE_URL + data?.image} alt={data?.name ?? ''} />*/}
                                    {/*        <h6>{data?.name}</h6>*/}
                                    {/*        <div className="set-item-price">*/}
                                    {/*            <div className="old">{data?.price} ₽</div>*/}
                                    {/*            <div className="new">{data?.priceInSet} ₽</div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <HiPlus className='set-icon' />*/}
                                    {/*    <div className="set-item">*/}
                                    {/*        {data?.set && data.set.map(set =>*/}
                                    {/*            <React.Fragment key={'service-' + set.service.id}>*/}
                                    {/*                <img src={'/imgs/service-img.png'} alt={set.service.name} />*/}
                                    {/*                <h6>{set.service.name}</h6>*/}
                                    {/*                <div className="set-item-price">*/}
                                    {/*                    <div className="old">{set.service.price} ₽</div>*/}
                                    {/*                    <div className="new">{set.service.priceInSet} ₽</div>*/}
                                    {/*                </div>*/}
                                    {/*            </React.Fragment>)}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<h4 className='main-font fw-5'>Итого вы экономите до 40%</h4>*/}
                                    {/*<div className="price-box">*/}
                                    {/*    <div>*/}
                                    {/*        <p className='price'>от 30 200 ₽</p>*/}
                                    {/*        <p className='info'>цена за одну штуку</p>*/}
                                    {/*    </div>*/}
                                    {/*    <button type='button' className='btn-3'>Хочу комплект</button>*/}
                                    {/*</div>*/}
                                </Col>
                            </Row>
                        </div>
                        <div className="area-4">
                            <div className='d-flex mt-5 mt-lg-0 mb-4'>
                                <button
                                    type='button'
                                    className={(tab === 1) ? 'btn-2 active' : 'btn-2'}
                                    onClick={() => setTab(1)}
                                >
                                    Описание
                                </button>
                                <button
                                    type='button'
                                    className={(tab === 2) ? 'btn-2 ms-3 active' : 'btn-2 ms-3'}
                                    onClick={() => setTab(2)}
                                >
                                    Характеристики
                                </button>
                            </div>

                            {
                                (tab === 1)
                                    ? <div className='text'>
                                        <h3>Описание</h3>
                                        <p>{data?.description}</p>
                                    </div>
                                    : <div className='text'>
                                        <h3>Характеристики</h3>
                                        <ul>
                                            {data?.parameters && data.parameters.map(param =>
                                                <li key={'param-' + param.ProductParamOption.name}>
                                                    <span className='fw-6'>{param.ProductParamOption.productParam.name}</span>{' '}
                                                    {param.ProductParamOption.name}
                                                </li>)}
                                        </ul>
                                    </div>
                            }
                        </div>
                    </div>
                </section>

                <section className='sec-14 mb-6'>
                    <h3 className='mb-4 mb-md-5'>Вам может понравится</h3>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        breakpoints={{
                            576: {
                                spaceBetween: 20,
                                slidesPerView: 2
                            },
                            768: {
                                spaceBetween: 20,
                                slidesPerView: 3
                            },
                            992: {
                                spaceBetween: 20,
                                slidesPerView: 4
                            },
                            1400: {
                                spaceBetween: 30,
                                slidesPerView: 4
                            },
                            1598: {
                                spaceBetween: 30,
                                slidesPerView: 5
                            }
                        }}
                    >
                        {data && data.similarProducts.map(product =>
                            <SwiperSlide key={'similar-' + product.similarProduct.id}>
                                <ProductCard data={product.similarProduct} />
                            </SwiperSlide>)}
                    </Swiper>
                </section>
            </Container>
        </main>
    )
}

export default Product