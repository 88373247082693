import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const About = () => {
    return (
        <main>
            <Container className='mt-4'>
                <Row className='gx-xxxl-5'>
                    <section className='sec-10 mb-5'>
                        <div className="title mb-4 mb-sm-5">
                            <h1 className="h2">О компании</h1>
                        </div>
                        <Row className='gy-4 gy-sm-5 gx-2 gx-sm-4 gx-xl-5'>
                            <Col xs={12} lg={8}>
                                <p className='px-xl-5 mb-4 mb-xl-5'>Компания "ОТК-Сервис" работает с 2011 года, имеет4 бригады профессиональных сертифицированных монтажников, которые работают профессиональным инструментом и имеют многолетний стаж работы.</p>
                                <Row className='justify-content-center gy-4 px-xl-5 gx-4 gx-xl-5' xs={2} sm={3}>
                                    <Col>
                                        <img src="https://mskgrup.ru/imgs/icons/safe.png" alt="safe" className='icon'/>
                                        <h6>Выезд специалиста на объект, замеры, консультация и доставка кондиционеров для наших клиентов Бесплатно.</h6>
                                    </Col>
                                    <Col>
                                        <img src="https://mskgrup.ru/imgs/icons/target.png" alt="target" className='icon' />
                                        <h6>За 11 лет работы смонтировано 5440 кондиционеров, сданы 150 обьектов по вентиляции.</h6>
                                    </Col>
                                    <Col>
                                        <img src="https://mskgrup.ru/imgs/icons/trustworthy.png" alt="trustworthy" className='icon' />
                                        <h6>Предоставляем гарантию на оборудование до 5 лет!</h6>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6} lg={4}>
                                <img src={'imgs/imgsert1.jpg'} alt="img2"/>
                            </Col>
                            <Col xs={6} lg={4}>
                                <img src="imgs/imgsert2.jpeg" alt="img2"/>
                            </Col>
                            <Col xs={12} lg={8}>
                                <p className='mb-4 mb-xl-5'></p>
                                <ul>
                                    <li>В работе применяем только качественные расходные материалы: используем толстостенную медную трубу с толщиной стенки 0,81мм и 0,89мм. Межблочный кабель негорючий ВВГнг.</li>
                                    <li>Наши клиенты: Иннополис,Префектура Старо-татарской слободы, Авиакомпания NORDWIN, сеть кальянных UNITY HALL, Банк "НЕЙВА",школа иностранных языков "АЛИБРА", Медицинские центры и сеть Стоматология "Смайл Клиник", гостинницы, кафе,рестораны,офисы, салоны красоты.</li>
                                    <li>С каждым годом растёт количество постоянных клиентов, многие из которых обращаются к нам повторно чтобы приобрести кондиционеры и вентиляционное оборудование в новые квартиры и коттеджи.</li>
                                    <li>Мы предлагаем клиентам широкий выбор оборудования от эконом до премиум класса, включая дизайнерские модели.</li>
                                </ul>
                            </Col>
                        </Row>
                    </section>
                </Row>
            </Container>
        </main>
    );
};

export default About;