import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {IPortfolio} from "../@types";
import { Swiper } from "swiper/react";
import {Col, Table} from "react-bootstrap";
import {SwiperSlide} from "swiper/react";
import {Navigation} from 'swiper';
import {BASE_URL} from "../api-config";
import {GetAllPortfolios, GetOnePortfolio} from "../services/portfolio";
import {useParams} from "react-router-dom";

const p:IPortfolio={
    id: 1,
    title: "Название данного кейса",
    subtitle: "eqweqw",
    image: "/upload/dce1868f-ee20-492a-90fc-176cbc9f49ee-.jpg",
    images: [
        "/upload/dce1868f-ee20-492a-90fc-176cbc9f49ee-.jpg",
        "/upload/993550fc-00a9-44bc-aa57-f7cd568a6d10-.jpg",
        "/upload/dce1868f-ee20-492a-90fc-176cbc9f49ee-.jpg",
    ],
    description: "<p><h3>Продаем очень нужную вещь, и при том вы сами это знаете, что она вам нужна. Купите сегодня, и можете спокойно спать ночью. Не будет Жарко, не будет холодною Будет хорошо! Давай покупай!</h3></p>",
    characteristics: [
        {
            id: 5,
            name: "Размеры",
            value: "12X30",
            portfolioId: 1
        }
    ]
}
const PortfolioPage = () => {
    const [portfolio, setPortfolio]= useState<IPortfolio[]>()
    const {id} = useParams()
    useEffect(()=>{
        GetOnePortfolio(id).then(res=>setPortfolio(res))
    }, [])
    return (
        <main>
            <Container className='mt-4'>
                <Row className='gx-xxxl-5'>
                    <section className='sec-10 mb-5'>
                        <div className="mb-4 mb-sm-5">
                            <h1 className="h2">{portfolio?.title}</h1>
                        </div>

                        <div className="position-relative px-sm-5 mb-5">
                            {portfolio?.images &&
                                <Swiper
                                    modules={[Navigation]}
                                    spaceBetween={20}
                                    slidesPerView={1}
                                    breakpoints={{
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                    }}
                                >
                                    {[...portfolio?.images, portfolio?.image]?.map((element, index) =>
                                        <SwiperSlide key={index}>
                                            <img src={BASE_URL+element} alt={portfolio?.title} className='img-fluid' style={{maxHeight:400}} />
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            }
                        </div>
                        {/*<Row xs={1} lg={2} className='flex-lg-row-reverse px-sm-5'>*/}
                        {/*    <Col>*/}
                        {/*        <h4>Технические характеристики проекта</h4>*/}
                        {/*        <hr/>*/}
                        {/*        <Table className={'w-100'}>*/}
                        {/*            <tbody>*/}
                        {/*            {portfolio?.characteristics?.map(({name, value}, index) =>*/}
                        {/*                <tr key={index}>*/}
                        {/*                    <td>{name}</td>*/}
                        {/*                    <td>{value}</td>*/}
                        {/*                </tr>*/}
                        {/*            )}*/}
                        {/*            </tbody>*/}
                        {/*        </Table>*/}
                        {/*    </Col>*/}
                        {/*    <Col className='mt-4 mt-lg-0'>*/}
                        {/*        <div dangerouslySetInnerHTML={{__html: portfolio?.description}}></div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </section>
                </Row>
            </Container>
        </main>
    );
};

export default PortfolioPage;