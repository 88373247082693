import React, {useState} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Link} from 'react-router-dom'
import ProductCard from '../components/ProductCard'
import Guarantee from '../components/svg/Guarantee'
import StarRating from '../components/utils/StarRating'
import NewsCard from '../components/NewsCard'
import Quiz from '../components/Quiz'

import FigureMain from '../components/svg/FigureMain'
import FigureMainMobile from '../components/svg/FigureMainMobile'

import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation} from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import {
    useGetCategoriesQuery,
    useGetCategoryBestDealsQuery,
    useGetHomePageProductsQuery
} from '../store/api/categoryApi'
import {useGetAllNewsQuery} from '../store/api/newsApi'
import {useGetUserReviewsQuery} from '../store/api/reviewApi'
import api from '../utils'
import {enqueueSnackbar} from 'notistack'
import {FetchApiError} from '@russh/fetch-api'
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from 'formik'

// --------------------------------------------------------------------------------

const Home: React.FC = () => {
    const [categoryId, setCategoryId] = useState(17)

    const categories = useGetCategoriesQuery()
    const bestDeals = useGetCategoryBestDealsQuery({ categoryId })

    const news = useGetAllNewsQuery()
    const reviews = useGetUserReviewsQuery()

    const homePageProducts = useGetHomePageProductsQuery()

    const initialValues = { phone: '', name: '' }
    const handleSubmit = async (data: typeof initialValues, { setErrors }: FormikHelpers<typeof initialValues>) => {
        try {
            await api.post('/requests', data)
            enqueueSnackbar('Заявка отправлена')
        } catch (e) {
            let error = e as FetchApiError
            enqueueSnackbar(error.message)
            error.errors && setErrors(error.errors)
        }
    }

    return (
        <main>
            <section className='sec-1'>
                <FigureMain className="d-none d-md-block" img={"imgs/img1.jpg"} />
                <FigureMainMobile className="d-md-none" img={"imgs/img1-mob.jpg"} />
                <Container className='h-100 pt-4 pt-md-0'>
                    <Row className='h-100 align-items-md-center'>
                        <Col md={8} xl={7}>
                            <h1>Продажа и монтаж кондиционеров, вентиляции и теплового оборудования </h1>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container>
                <section className='sec-2 mb-3'>
                    <div className="d-md-none row row-cols-md-2 gx-3">
                        {homePageProducts.data?.slice(0,1)?.map(product =>
                            <div key={'home-page-product-' + product.id} className={'col'}>
                                <ProductCard data={product} />
                            </div>)
                        }
                    </div>
                    <div className="d-none d-md-flex d-lg-none row row-cols-md-2 gx-3">
                        {homePageProducts.data?.slice(0,2)?.map(product =>
                            <div key={'home-page-product-' + product.id} className={'col'}>
                                <ProductCard data={product} />
                            </div>)
                        }
                    </div>

                    <div className="d-none d-lg-flex row row-cols-lg-3 gx-3 gx-xl-4">
                        {homePageProducts.data?.slice(0,3)?.map(product =>
                            <div key={'home-page-product-' + product.id} className={'col'}>
                                <ProductCard data={product} />
                            </div>)
                        }
                    </div>
                </section>

                <div className="d-md-none">
                    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 row-cols-xxxl-5 gy-3 gx-3">
                        {homePageProducts.data?.slice(1)?.map(product =>
                            <div key={'home-page-product-' + product.id} className={'col'}>
                                <ProductCard data={product} />
                            </div>)
                        }
                    </div>
                </div>

                <div className="d-none d-md-block d-lg-none">
                    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 row-cols-xxxl-5 gy-3 gx-3">
                        {homePageProducts.data?.slice(2)?.map(product =>
                            <div key={'home-page-product-' + product.id} className={'col'}>
                                <ProductCard data={product} />
                            </div>)
                        }
                    </div>
                </div>

                <div className="d-none d-lg-block">
                    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 row-cols-xxxl-5 gy-3 gx-3">
                        {homePageProducts.data?.slice(3)?.map(product =>
                            <div key={'home-page-product-' + product.id} className={'col'}>
                                <ProductCard data={product} />
                            </div>)
                        }
                    </div>

                </div>

            </Container>

            <section className='mt-2 sec-3 mb-6'>
                <Container>
                    <h2 className='d-none d-mb-block'>Каталог товаров</h2>
                    <ul className="grid">
                        <li className="grid-1">
                            <figure>
                                <figcaption><h3>Кондиционеры</h3></figcaption>
                                <img src="imgs/img2.jpg" alt="Кондиционеры" />
                            </figure>
                        </li>
                        <li className="grid-2">
                            <figure>
                                <img src="imgs/img3.jpg" alt="Кондиционеры" />
                                <figcaption><h3>Бризеры</h3></figcaption>
                            </figure>
                        </li>
                        <li className="grid-3">
                            <figure>
                                <figcaption><h3>Вентиляционое оборудование</h3></figcaption>
                                <img src="imgs/img4.jpg" alt="Вентиляционое оборудование" />
                            </figure>
                        </li>
                    </ul>
                </Container>
            </section>

            <section className='sec-4 mb-6'>
                <Container>
                    <h2>ОТК-СЕРВИС доверяют более 2&nbsp;000 клиентов</h2>
                    <Row className='gx-4 gx-xxl-5'>
                        <Col md={5}>
                            <img src="imgs/img5.jpg" alt="" className='img-1 mb-4' />
                            <p></p>
                        </Col>
                        <Col md={7} className='d-flex flex-column flex-md-column-reverse mt-5 mt-md-0'>
                            <Guarantee className="svg" />
                            <Row className='gx-3 gx-xxl-4 mb-4'>
                                <Col xs={12} xl={8}>
                                    <h4>Вы можете не только приобрести</h4>
                                    <p>У нас вы можете не только приобрести оборудование, но и заказать его установку. Также мы оказываем услуги по ремонту и обслуживанию.
                                        <br/>
                                        В процессе работ используются:
                                        <br/>
                                        Строительный пылесос (минимум пыли и грязи)
                                        <br/>
                                        Толстостенные Медные трубки (не алюминий)
                                        <br/>
                                        Профессиональный Инструмент (Bosch Германия)</p>
                                </Col>
                                <Col xl={4} className='d-none d-xl-block'>
                                    <img src="imgs/img6.jpg" alt="" className='img-2' />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='sec-5 mb-6'>
                <Container>
                    <h2 className="h1 text-center">Лучшие предложения <img src="imgs/icons/price-rate.svg" alt="rate" className='d-none d-md-inline' /> месяца <img src="imgs/icons/price-rate.svg" alt="rate" className='d-md-none' /></h2>

                    <Swiper
                        className='menu-slider'
                        spaceBetween={30}
                        slidesPerView={'auto'}

                    >
                        {categories.data && categories.data.map(cat =>
                            <SwiperSlide key={'home-page-cat-' + cat.id} >
                                <button
                                    type='button'
                                    className={categoryId === cat.id ? 'btn-2 active' : 'btn-2'}
                                    onClick={() => setCategoryId(cat.id)}
                                >
                                    {cat.name}
                                </button>
                            </SwiperSlide>)}
                    </Swiper>

                    <Row className='justify-content-center position-relative'>
                        <Col xs={12} xxl={10}>
                            <Swiper
                                modules={[Navigation]}
                                className='offers-slider'
                                spaceBetween={5}
                                slidesPerView={2}
                                navigation={true}
                                breakpoints={{
                                    576: {
                                        spaceBetween: 10,
                                        slidesPerView: 2
                                    },
                                    768: {
                                        spaceBetween: 30,
                                        slidesPerView: 2,
                                        navigation: {
                                            enabled: true,
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                        },
                                    },
                                    992: {
                                        spaceBetween: 30,
                                        slidesPerView: 3
                                    },
                                }}
                            >
                                {bestDeals.data && bestDeals.data.map(product =>
                                    <SwiperSlide key={'best-deal-' + product.id}>
                                        <ProductCard data={product} />
                                    </SwiperSlide>)}
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='sec-6 mb-6'>
                <Container className='position-relative'>
                    <div className="box">
                        <h2>Оставьте заявку на подбор кондиционера</h2>
                        <h5 className='text-center text-lg-start mt-4 mt-lg-0'>И мы перезвоним в течение 20 минут, чтобы ответить на все ваши вопросы</h5>

                        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                            <Form>
                                <Field name='phone' type="tel" placeholder='+7 ___-___-__-__' />
                                <ErrorMessage name='phone' component='div' className='error-message' />

                                <Field name='name' type="text" placeholder='Имя' />
                                <ErrorMessage name='name' component='div' className='error-message' />

                                <button type='submit' className="btn-1 fw-6 w-xs-100">Отправить</button>
                                <p>Нажимая кнопку «Отправить», вы даёте согласие на обработку персональных данных</p>
                            </Form>
                        </Formik>

                    </div>
                    <img src={'/imgs/woman.png'} alt="Woman" className='img' />
                </Container>
            </section>

            <section className='sec-7 mb-6'>
                <Container>
                    <h2 className='text-center mb-md-5'>Отзывы клиентов ОТК-Сервис</h2>
                    <div className="position-relative">
                        <Swiper
                            modules={[Navigation]}
                            className='reviews-slider'
                            loop={true}
                            spaceBetween={30}
                            slidesPerView={1}
                            loopedSlides={2}
                            centeredSlides={true}
                            navigation
                            breakpoints={{
                                768: {
                                    spaceBetween: 30,
                                    slidesPerView: 2
                                },
                                992: {
                                    spaceBetween: 30,
                                    slidesPerView: 3
                                },
                            }}
                        >
                            {reviews.data && reviews.data.map(review =>
                                <SwiperSlide key={'review-' + review.id}>
                                    <div className="review">
                                        <StarRating rate={review.rate} />
                                        <p>{review.review}</p>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </Container>
            </section>

            <section className='sec-blog mb-6'>
                <Container>
                    <div className="title mb-4 mb-md-5">
                        <h2 className='mb-0'>Новости</h2>
                        <Link to='blog' className='btn-3 me-lg-5'>Читать все записи</Link>
                    </div>
                    <Swiper
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={2}
                        breakpoints={{
                            576: {
                                spaceBetween: 15,
                                slidesPerView: 2
                            },
                            768: {
                                spaceBetween: 15,
                                slidesPerView: 3
                            },
                            992: {
                                spaceBetween: 30,
                                slidesPerView: 3
                            },
                            1200: {
                                spaceBetween: 30,
                                slidesPerView: 4
                            },
                            1400: {
                                spaceBetween: 55,
                                slidesPerView: 4
                            },
                        }}
                    >
                        {news.data && news.data.map(news =>
                            <SwiperSlide key={'news' + news.id}>
                                <NewsCard data={news} />
                            </SwiperSlide>)}
                    </Swiper>
                </Container>
            </section>

            <section className='sec-8 mb-6'>
                <Container>
                    <img src={'/imgs/man.png'} alt="homuncule" className='homuncule' />
                    <div className="title">
                        <h2>Вызовите мастера сейчас</h2>
                        <h4 className='fw-4 mb-0'>Ответьте на 4 вопроса и получите скидку на монтаж кондиционера</h4>
                    </div>
                    <Quiz />
                </Container>
            </section>
        </main>
    )
}

export default Home