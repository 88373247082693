import React from 'react'
import PLaix from '../svg/PLaix'

// --------------------------------------------------------------------------------

const Devs = (props) => {
  return (
    <div className={"devs " + props.className}>
      <img src={'/imgs/plaixIcon.svg'} alt="Plaix" className='me-2' />
      <span>Создано в</span>
      <PLaix title={"Создано в Plaix"} className='ms-1' />
    </div>
  )
}

export default Devs