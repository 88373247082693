import React from 'react'
import { Link } from 'react-router-dom'

// --------------------------------------------------------------------------------

interface CategoryCardProps {
  title?: string
  img?: string
  link: string
}

const CategoryCard: React.FC<CategoryCardProps> = ({ title, img, link }) => {
  return (
    <figure className='category-card'>
      <figcaption>
        <h4 className='fw-5'><Link to={link} className='stretched-link'>{title}</Link></h4>
      </figcaption>
      <img src={img} alt={title} />
    </figure>
  )
}

export default CategoryCard