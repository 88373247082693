import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumbs from '../components/Breadcrumbs'
import CartItem from '../components/CartItem'
import CartItemService from '../components/CartItemService'
import { useClearCartMutation, useGetCartQuery, useUpdateCartMutation } from '../store/api/cartApi'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik'
import { FetchApiError } from '@russh/fetch-api'
import { enqueueSnackbar } from 'notistack'
import { HiOutlineXCircle, HiOutlineXMark } from 'react-icons/hi2'
import api from '../utils'
import { Request } from '../@types'
import { Link } from 'react-router-dom'

interface Prod {
    productId: number
    productQty: number
}

// --------------------------------------------------------------------------------

const ShoppingCart: React.FC = () => {
    const [submitForm, setSubmitForm] = useState(false)
    const [order, setOrder] = useState<Request>()

    const { data } = useGetCartQuery()
    const [clearCart] = useClearCartMutation()

    const initialValues = { phone: '', name: '' }
    const handleSubmit = async (values: typeof initialValues, { setErrors }: FormikHelpers<typeof initialValues>) => {
        if (!data) return

        let total = 0
        const products = data.map(product => {
            total += product.product.price * product.productQty;
            return { productId: product.productId, productQty: product.productQty }
        })

        try {
            const res = await api.post('/requests', { ...values, total, products }) as Request
            clearCart()
            setOrder(res)
        } catch (e) {
            let error = e as FetchApiError
            enqueueSnackbar(error.message)
            error.errors && setErrors(error.errors)
        }
    }

    if (!data) return null

    return (
        <main>
            <Container>
                <Breadcrumbs title='Корзина' />

                <section className='sec-15 mb-6'>
                    <div className="title mb-4 mb-sm-5">
                        <h1 className='h2'>Корзина</h1>
                    </div>

                    {data && data.length > 0
                        ?
                        <Row xs={1} xl={2}>
                            <Col className='pe-xxxl-5'>
                                <ul className='list-unstyled pb-sm-4 pb-xxl-5 mb-3'>

                                    {data.map(product =>
                                        <li className='mb-4' key={'cart-product-' + product.productId}>
                                            <CartItem data={product} />
                                        </li>)}
                                </ul>

                                {/* {data[0].product.set[0].service &&
                                    <>
                                        <label className='mt-4 mt-xxl-5 mb-4'>
                                            <div className='fs-12 fw-5 me-4'>Заказать установку</div>
                                            <input type="checkbox" defaultChecked={true} />
                                        </label>
                                        <CartItemService data={data[0]} />
                                    </>} */}
                            </Col>
                            <Col className='ps-xxxl-5 mt-5 mt-xl-0'>
                                <div className="position-relative d-flex align-items-center justify-content-end">
                                    <div className="box">
                                        <h3 className='fw-6'>Товаров и услуг</h3>
                                        <h5>на сумму 38 200₽</h5>
                                        <p className='mt-4'>После оформления заказа оператор перезвонит вам и уточнит стоимость товаров и услуг</p>
                                        <button className='btn-1 fs-12 mt-4' onClick={() => setSubmitForm(true)}>Оформить</button>
                                    </div>
                                    <img src={'/imgs/cart-img.png'} alt="корзина" className='cart-img' />
                                </div>
                            </Col>
                        </Row>
                        :
                        <div>Добавьте товар в корзину</div>}
                </section>
            </Container>
            <section className={submitForm ? 'sec-12 submit-order' : 'sec-12 submit-order d-none'}>
                {!order
                    ?
                    <div className="form-box">
                        <h2 className='h3 mb-5'>Укажите контактные данные<br />для оформления заказа</h2>

                        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                            <Form className='mb-4'>
                                <Field name='phone' type="tel" placeholder='+7 ___-___-__-__' />
                                <ErrorMessage name='phone' component='div' className='error-message' />

                                <Field name='name' type="text" placeholder='Имя' />
                                <ErrorMessage name='name' component='div' className='error-message' />

                                <button type='submit' className="w-xs-100 btn-3 fw-6 px-3">Отправить</button>
                                <button type='button' className="close" onClick={() => setSubmitForm(false)}><HiOutlineXCircle /></button>
                            </Form>
                        </Formik>
                        <p>Нажимая кнопку «Отправить», вы даёте согласие на обработку персональных данных</p>

                    </div>
                    :
                    <div className="order-box">
                        <Row>
                            <Col md={9}>
                                <h3>Заказ №{order.id} на сумму {order.total.toLocaleString('ru')}₽<br />отправлен в обработку</h3>
                                <p>Оператор перезвонит вам в течение 20 минут</p>
                                <Link to='/catalog'>
                                    <button type='button' className="w-xs-100 btn-3 fw-6 px-3 mt-4">Вернуться в каталог</button>
                                </Link>
                            </Col>
                            <Col md={3}>
                                <img src="/imgs/3d-casual-life-check-mark-in-box-side-view-blue 1.png" alt="check" />
                            </Col>
                        </Row>

                    </div>}
            </section>
        </main>
    )
}

export default ShoppingCart