import React from 'react'
import { Link } from 'react-router-dom'
import { HiOutlineChevronRight } from "react-icons/hi2"

// --------------------------------------------------------------------------------

interface BreadcrumbsProps {
  title: string
  link?: string
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ title, link }) => {
  return (
    <nav className='breadcrumbs'>
      <ul>
        <li>
          <Link to="/">Главная</Link>
        </li>
        <li className='divider'><HiOutlineChevronRight /></li>
        <li>
          {title}
        </li>
      </ul>
    </nav>
  )
}

export default Breadcrumbs