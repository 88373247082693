import { createApi } from '@reduxjs/toolkit/query/react'
import { ApiGetCategory, ApiGetCategoryProduct, ApiGetCategoryProducts } from '../../@types/api'
import api from '../../utils'
import { UserReview } from '../../@types'

// --------------------------------------------------------------------------------

export const reviewApi = createApi({
    reducerPath: 'reviewApi',
    baseQuery: api.baseQuery,
    keepUnusedDataFor: 60 * 60,
    tagTypes: ['Reviews'],
    endpoints: (builder) => ({

        getUserReviews: builder.query<UserReview[], void>({
            query: () => ({ url: `/reviews` }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Reviews' as const, id })), 'Reviews']
                    : ['Reviews']
        }),

    }),
})

export const {
    useGetUserReviewsQuery,
} = reviewApi