import {apiRoutes, BASE_URL} from "../api-config";

export const GetAllPortfolios = async () =>{
    const result = await fetch(`${BASE_URL}/${apiRoutes.GET_PORTFOLIO}`)
    return result.json()
}

export const GetOnePortfolio = async (id) =>{
    const result = await fetch(`${BASE_URL}/${apiRoutes.GET_PORTFOLIO}/${id}`)
    return result.json()
}