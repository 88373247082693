import React from 'react'
import Container from 'react-bootstrap/Container'
import NewsCard from '../components/NewsCard'
import Breadcrumbs from '../components/Breadcrumbs'
import { useGetAllNewsQuery } from '../store/api/newsApi'

// --------------------------------------------------------------------------------

const Blog: React.FC = () => {

  const { data } = useGetAllNewsQuery()

  return (
    <main>
      <Container>
        <Breadcrumbs title='Новости' />

        <section className='sec-blog mb-6'>
          <div className="title blog">
            <h1 className='h2'>Новости </h1>
            <img src={'/imgs/article-img.png'} alt="article-img" />
          </div>

          <ul className='list-unstyled row row-cols-2 row-cols-md-3 row-cols-xl-4 gy-4 gy-sm-5 gx-3 gx-lg-4 gx-xxl-5'>
            {data && data.map(news =>
              <li key={'news-page-news-' + news.id}>
                <NewsCard data={news} />
              </li>
            )}
          </ul>
        </section>
      </Container>
    </main>
  )
}

export default Blog