import { createApi } from '@reduxjs/toolkit/query/react'
import { ApiGetCategory, ApiGetCategoryProduct, ApiGetCategoryProducts } from '../../@types/api'
import api from '../../utils'
import { News } from '../../@types'

// --------------------------------------------------------------------------------

export const newsApi = createApi({
    reducerPath: 'newsApi',
    baseQuery: api.baseQuery,
    keepUnusedDataFor: 60 * 60,
    tagTypes: ['News'],
    endpoints: (builder) => ({

        getAllNews: builder.query<News[], void>({
            query: () => ({ url: `/news` }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'News' as const, id })), 'News']
                    : ['News']
        }),

        getOneNews: builder.query<News, string | undefined>({
            query: (id) => ({ url: `/news/${id}` }),
            providesTags: (result) =>
                result ? [{ type: 'News' as const, id: result.id }] : ['News']
        }),

    }),
})

export const {
    useGetAllNewsQuery,
    useGetOneNewsQuery
} = newsApi