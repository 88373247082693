import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import AppLayout from '../layouts/AppLayout'
import Home from '../pages/Home'
import Blog from '../pages/Blog'
import NotFound from '../pages/NotFound'
import Article from '../pages/Article'
import Catalog from '../pages/Catalog'
import Services from '../pages/Services'
import Category from '../pages/Category'
import Product from '../pages/Product'
import ShoppingCart from '../pages/ShoppingCart'
import ServicePage from '../pages/ServicePage'
import Portfolio from '../pages/Portfolio'
import SearchPage from '../pages/SearchPage'
import InDeveloping from "../pages/InDeveloping";
import PortfolioPage from "../pages/PortfolioPage";
import About from "../pages/About";
import Contacts from "../pages/Contacts";

// --------------------------------------------------------------------------------

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>

      <Route index element={<Home />} />

      <Route path="catalog" element={<Catalog />} />
      <Route path="catalog/:category" element={<Category />} />
      <Route path="catalog/:category/:product" element={<Product />} />

      <Route path="cart" element={<ShoppingCart />} />

      <Route path="services" element={<Services />} />
      <Route path="services/:service" element={<ServicePage />} />

      <Route path="blog" element={<Blog />} />
      <Route path="blog/:article" element={<Article />} />
      <Route path="portfolio" element={<Portfolio />} />
      <Route path="portfolio/:id" element={<PortfolioPage />} />

      <Route path="payment" element={<InDeveloping />} />
      <Route path="about" element={<About />} />
      <Route path="contacts" element={<Contacts />} />
      <Route path="policy" element={<InDeveloping />} />

      <Route path="search" element={<SearchPage />} />

      <Route path="*" element={<NotFound />} />

    </Route>
  )
)

const AppRouter = () => {
  return <RouterProvider router={router} />
};

export default AppRouter;