import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import Offer from '../components/Offer'
import { useGetServicesQuery } from '../store/api/servicesApi'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik'
import api from '../utils'
import { enqueueSnackbar } from 'notistack'
import { FetchApiError } from '@russh/fetch-api'

// --------------------------------------------------------------------------------

const Services: React.FC = () => {

  const { data } = useGetServicesQuery()

  const initialValues = { phone: '', name: '' }
  const handleSubmit = async (data: typeof initialValues, { setErrors }: FormikHelpers<typeof initialValues>) => {
    try {
      await api.post('/requests', data)
      enqueueSnackbar('Заявка отправлена')
    } catch (e) {
      let error = e as FetchApiError
      enqueueSnackbar(error.message)
      error.errors && setErrors(error.errors)
    }
  }

  return (
    <main>
      <Container className='mt-4'>
        <Row className='gx-xxxl-5'>
          <Col xl={9}>
            <section className='sec-10 mb-5'>
              <div className="title mb-4 mb-sm-5">
                <h1 className="h2">Услуги</h1>
              </div>

              <ul className='list-unstyled row row-cols-1 row-cols-md-2 g-3 g-xxl-4'>
                {data && data.map(service =>
                  <li key={`service-${service.id}`}>
                    <Link to={`/services/service-${service.id}`} className='btn-5 w-100'>{service.name}</Link>
                  </li>
                )}
              </ul>
            </section>

            <section className='sec-6 no-img mb-6'>
              <div className="box gradient">
                <h3>Возникли вопросы?</h3>
                <h5 className='text-center text-lg-start mt-4 mt-lg-0'>Оставьте заявку и мы перезвоним вам в течение 20 минут</h5>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                  <Form>
                    <Field name='phone' type="tel" placeholder='+7 ___-___-__-__' />
                    <ErrorMessage name='phone' component='div' className='error-message' />

                    <Field name='name' type="text" placeholder='Имя' />
                    <ErrorMessage name='name' component='div' className='error-message' />

                    <button type='submit' className="btn-1 fw-6 px-3">Оставить заявку</button>

                    <p>Нажимая кнопку «Отправить», вы даёте согласие на обработку персональных данных</p>
                  </Form>
                </Formik>
              </div>
            </section>
          </Col>
          <Col xl={3} className='d-none d-xl-block'>
            <Offer />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Services