import React from 'react'
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi2"
import { ApiCartItem } from '../@types/api'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../api-config'
import { useRemoveFromCartMutation, useUpdateCartMutation } from '../store/api/cartApi'
import { enqueueSnackbar } from 'notistack'
import { FetchApiError } from '@russh/fetch-api'

// --------------------------------------------------------------------------------

interface CartItemProps {
    data: ApiCartItem
}

const CartItem: React.FC<CartItemProps> = ({ data }) => {

    const [updateCart] = useUpdateCartMutation()
    const [removeFromCart] = useRemoveFromCartMutation()

    const handleIncrease = async () => {
        const { productId, productQty } = data
        try {
            await updateCart({ productId, productQty: productQty + 1 })
        } catch (e) {
            let error = e as FetchApiError
            enqueueSnackbar(error.message)
        }
    }

    const handleDecrease = async () => {
        const { productId, productQty } = data
        if (data.productQty === 1) {

            try {
                await removeFromCart({ productId })
            } catch (e) {
                let error = e as FetchApiError
                enqueueSnackbar(error.message)
            }

        } else {

            try {
                await updateCart({ productId, productQty: productQty - 1 })
            } catch (e) {
                let error = e as FetchApiError
                enqueueSnackbar(error.message)
            }
        }
    }

    if (!data) return null

    return (
        <div className="cart-item">
            <Link to={`/catalog/category-${data.product.categoryId}/product-${data.product.id}`}>
                <img src={BASE_URL + data.product.image} alt={data.product.name ?? ''} className="cart-item-img" />
            </Link>
            <div className='cart-item-info'>
                <h5 className='fw-6'>{data.product.name}</h5>
                <ul>
                    {data.product.parameters.slice(0, 3).map(param =>
                        <li key={'cart-item-' + data.product.id + '-spec-' + param.ProductParamOption.name}>
                            <span className='fw-6'>{param.ProductParamOption.productParam.name}</span>{' '}
                            {param.ProductParamOption.name}
                        </li>)}
                </ul>
                <div className="d-flex flex-column flex-md-row-reverse justify-content-between mt-2">
                    <h3 className='mb-3 mb-md-0'>{data.product.price * data.productQty} ₽</h3>
                    <div className='d-flex align-items-center change-qty'>
                        <button type='button' onClick={handleDecrease}>-</button>
                        <span className='d-flex justify-content-center'>{data.productQty}</span>
                        <button type='button' onClick={handleIncrease}>+</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItem