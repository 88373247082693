import { createApi } from '@reduxjs/toolkit/query/react'
import { ApiCartItem, ApiGetCategory } from '../../@types/api'
import api from '../../utils'
import { Cart } from '../../@types'

// --------------------------------------------------------------------------------

export const cartApi = createApi({
    reducerPath: 'cartApi',
    baseQuery: api.baseQuery,
    keepUnusedDataFor: 60 * 60,
    tagTypes: ['Cart'],
    endpoints: (builder) => ({

        getCart: builder.query<ApiCartItem[], void>({
            query: () => ({ url: `/cart` }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ productId }) => ({ type: 'Cart' as const, id: productId })), 'Cart']
                    : ['Cart']
        }),

        addToCart: builder.mutation<ApiCartItem, unknown>({
            query: (payload) => ({
                url: `/cart`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['Cart']
        }),

        updateCart: builder.mutation<ApiCartItem, { productId: number, productQty: number }>({
            query: ({ productId, productQty }) => ({
                url: `/cart/${productId}`,
                method: 'PATCH',
                body: { productQty }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Cart', id: arg.productId }]
        }),

        removeFromCart: builder.mutation<ApiCartItem, { productId: number }>({
            query: ({ productId }) => ({
                url: `/cart/${productId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Cart']
        }),

        clearCart: builder.mutation<ApiCartItem, void>({
            query: () => ({
                url: `/cart`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Cart']
        }),

    }),
})

export const {
    useGetCartQuery,
    useAddToCartMutation,
    useClearCartMutation,
    useUpdateCartMutation,
    useRemoveFromCartMutation
} = cartApi