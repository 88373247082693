import React, {useEffect, useMemo, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Breadcrumbs from '../components/Breadcrumbs'
import ProductCard from '../components/ProductCard'
import LongDash from '../components/svg/LongDash'
import { HiAdjustmentsHorizontal, HiXMark } from "react-icons/hi2"
import Offer from '../components/Offer'
import { useGetCategoriesQuery, useGetCategoryProductsQuery, useGetCategoryQuery } from '../store/api/categoryApi'
import { useParams } from 'react-router-dom'
import { BASE_URL } from '../api-config'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { FetchApiError } from '@russh/fetch-api'
import api from '../utils'
import { ApiGetCategory } from '../@types/api'
import NavPagination from '../components/NavPagination'

// --------------------------------------------------------------------------------

const Category: React.FC = () => {
    const [isShowFilter, setIsShowFilter] = useState(false)
    const handleCloseFilter = () => setIsShowFilter(false)
    const handleShowFilter = () => setIsShowFilter(true)
    const [page, setPage] = useState(1)
    const { category } = useParams()

    const cats = useGetCategoriesQuery()
    const [currentCategory, setCurrentCategory] = useState<ApiGetCategory>()

    const [sort, setSort] = useState('asc')
    const [minPrice, setMinPrice] = useState<number>()
    const [maxPrice, setMaxPrice] = useState<number>()
    const [paramOptions, setParamOptions] = useState<number[]>([])

    useEffect(() => {
        if (cats.data && !currentCategory) {
            const cat = cats.data.filter(cat => cat.id.toString() === category?.split('-')[1])
            setCurrentCategory(cat[0])
        }
    }, [cats])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [page])

    const { data } = useGetCategoryProductsQuery({
        categoryId: currentCategory?.id, params: {
            page: page.toString(), sort,
            filter: JSON.stringify(Object.fromEntries(Object.entries({ minPrice, maxPrice, paramOptions }).filter(([_, v]) => !!v)))
        }
    })

    const requestInitialValues = { phone: '', name: '' }
    const handleRequestSubmit = async (data: typeof requestInitialValues, { setErrors }: FormikHelpers<typeof requestInitialValues>) => {
        try {
            await api.post('/requests', data)
            enqueueSnackbar('Заявка отправлена')
        } catch (e) {
            let error = e as FetchApiError
            enqueueSnackbar(error.message)
            error.errors && setErrors(error.errors)
        }
    }

    const handleparams = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = Number(e.target.value)
        switch (e.target.checked) {
            case true: {
                setParamOptions([...paramOptions, targetValue])
                break
            }
            case false: {
                let arr = paramOptions.slice().filter((v) => v !== targetValue)
                setParamOptions(arr)
                break
            }
            default: return
        }
    }

    const sortedParams=useMemo(()=>
        currentCategory?.paramsOrder
            ?.map(element=>currentCategory?.productParams
                ?.find(item=>item.id==element)
            )
    , [currentCategory?.paramsOrder])

    return (
        <main>
            <Container>
                <section className='sec-12 mb-6'>
                    <h1 className='mb-4'>{currentCategory?.name}</h1>
                    <Breadcrumbs title={currentCategory?.name ?? ''} />

                    <Row className='gx-xxxl-5 mt-4 mt-sm-5'>
                        <Col lg={3}>
                            <Offcanvas className="offcanvas-filter" show={isShowFilter} onHide={handleCloseFilter} placement={'end'} responsive="lg">
                                <Offcanvas.Body>
                                    <button type='button' onClick={handleCloseFilter} className='btn-5 close d-lg-none'>
                                        <HiXMark />
                                    </button>
                                    <form action='#' className='form-filter'>
                                        <fieldset className='mb-5'>
                                            <legend>Цена, ₽</legend>
                                            <div className='d-flex align-items-center mt-3'>
                                                <input
                                                    type="number"
                                                    placeholder='От'
                                                    className='flex-1'
                                                    onChange={e => setTimeout(() => setMinPrice(e.target.valueAsNumber), 1500)}
                                                />
                                                <LongDash className="gray mx-2" />
                                                <input
                                                    type="number"
                                                    placeholder='До'
                                                    className='flex-1'
                                                    onChange={e => setTimeout(() => setMaxPrice(e.target.valueAsNumber), 1500)}
                                                />
                                            </div>
                                        </fieldset>
                                        <Accordion flush>
                                            {sortedParams?.map((param, index) =>
                                                <Accordion.Item eventKey={index.toString()} as="fieldset" key={'param-' + param.id}>
                                                    <Accordion.Header as="legend">{param.name}</Accordion.Header>
                                                    <Accordion.Body as="ul">
                                                        {param.options.map(option =>
                                                            <li key={'param-option-' + option.id}>
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value={option.id}
                                                                        onChange={handleparams}
                                                                    />
                                                                    <span>{option.name}</span>
                                                                </label>
                                                            </li>)}
                                                    </Accordion.Body>
                                                </Accordion.Item>)}
                                        </Accordion>
                                    </form>
                                </Offcanvas.Body>
                            </Offcanvas>

                            <Offer className={'d-none d-lg-block mb-5'} />
                        </Col>
                        <Col lg={9}>
                            <div className='d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap mb-5'>
                                <ul className='subcategories'>
                                    {cats.data && cats.data.map(cat =>
                                        <li key={'caterory-tab-' + cat.id}>
                                            <button
                                                type='button'
                                                className={cat.id === currentCategory?.id ? 'btn-2 active' : 'btn-2'}
                                                onClick={() => setCurrentCategory(cat)}
                                            >{cat.name}</button>
                                        </li>)}

                                </ul>

                                <select defaultValue="asc" className='btn' style={{border:'1px solid black'}} onChange={e => setSort(e.target.value)}>
                                    <option value="asc">Сначала дешёвые</option>
                                    <option value="desc">Сначала дорогие</option>
                                </select>

                                <button type='button' className='d-lg-none btn-1 p-2' onClick={handleShowFilter}><HiAdjustmentsHorizontal className='fs-17' /></button>
                            </div>

                            <ul className='list-unstyled row row-cols-2 row-cols-md-3 gx-2 gx-sm-3 gx-xl-4 gx-xxxl-5 gy-4 gy-sm-5'>
                                {data?.products && data.products.slice(0, 6).map(product =>
                                    <li key={'product-' + product.id}>
                                        <ProductCard data={product} />
                                    </li>
                                )}
                            </ul>

                            <div className="box">
                                <h2 className='h1 mb-4'>Возникли вопросы?</h2>
                                <h5 className='mt-4 mt-lg-0'>Оставьте заявку и мы перезвоним вам в течение 20 минут</h5>

                                <Formik initialValues={requestInitialValues} onSubmit={handleRequestSubmit} enableReinitialize>
                                    <Form className='mb-4'>
                                        <Field name='phone' type="tel" placeholder='+7 ___-___-__-__' />
                                        <ErrorMessage name='phone' component='div' className='error-message' />

                                        <Field name='name' type="text" placeholder='Имя' />
                                        <ErrorMessage name='name' component='div' className='error-message' />

                                        <button type='submit' className="w-xs-100 btn-3 fw-6 px-3">Оставить заявку</button>
                                    </Form>
                                </Formik>
                                <p>Нажимая кнопку «Отправить», вы даёте согласие на обработку персональных данных</p>
                            </div>

                            <ul className='list-unstyled row row-cols-2 row-cols-md-3 gx-2 gx-sm-3 gx-xl-4 gx-xxxl-5 gy-4 gy-sm-5 mt-3'>
                                {data?.products && data.products.slice(6).map(product =>
                                    <li key={'product-' + product.id}>
                                        <ProductCard data={product} />
                                    </li>
                                )}
                            </ul>

                            <NavPagination className='nav-pagination' pages={data?.pages} setPage={setPage} />
                        </Col>
                    </Row>
                </section>
            </Container>
        </main>
    )
}

export default Category