import React from 'react'
import Container from 'react-bootstrap/Container'
import NewsCard from '../components/NewsCard'
import Breadcrumbs from '../components/Breadcrumbs'
import { Link, useParams } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { useGetAllNewsQuery, useGetOneNewsQuery } from '../store/api/newsApi'
import { BASE_URL } from '../api-config'

// --------------------------------------------------------------------------------

const Article: React.FC = () => {
    const { article } = useParams()
    const { data } = useGetOneNewsQuery(article?.split('-')[1])
    const news = useGetAllNewsQuery()

    const markup = { __html: data?.text ?? '' }

    return (
        <main>
            <Container>
                <Breadcrumbs title={data?.title ?? ''} />

                <article className='mb-6'>
                    <div className="d-flex justify-content-between align-items-center mb-4 mb-md-5">
                        <h1 className="h3 mb-0">{data?.title}</h1>
                        <Link to="/blog" className='btn-1'>Назад</Link>
                    </div>
                    <div className="text">
                        <img src={BASE_URL + data?.image} alt={data?.imageAlt ?? ''} />
                        <p dangerouslySetInnerHTML={markup} />
                    </div>
                </article>

                <section className='sec-8 mb-6'>
                    <div className="title mb-4 mb-md-5">
                        <h2>Другие статьи</h2>
                    </div>

                    <Swiper
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={2}
                        breakpoints={{
                            576: {
                                spaceBetween: 15,
                                slidesPerView: 2
                            },
                            768: {
                                spaceBetween: 15,
                                slidesPerView: 3
                            },
                            992: {
                                spaceBetween: 30,
                                slidesPerView: 3
                            },
                            1200: {
                                spaceBetween: 30,
                                slidesPerView: 4
                            },
                            1400: {
                                spaceBetween: 55,
                                slidesPerView: 4
                            },
                        }}
                    >
                        {news.data && news.data.map(news =>
                            <SwiperSlide key={'news' + news.id}>
                                <NewsCard data={news} />
                            </SwiperSlide>)}
                    </Swiper>
                </section>
            </Container>
        </main>
    )
}

export default Article