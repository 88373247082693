import React from 'react'

// --------------------------------------------------------------------------------

interface SvgProps {
  title?: string
  className?: string
}

const LongDash: React.FC<SvgProps> = ({ title, className }) => {
  return (
    <svg className={className} width="30" height="2" viewBox="0 0 30 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1H30" stroke="currentColor" />
    </svg>
  )
}

export default LongDash