import React from 'react'
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs"

// --------------------------------------------------------------------------------

interface StarRatingProps {
  rate?: number
}

const StarRating: React.FC<StarRatingProps> = ({ rate }) => {
  const arr = [1, 2, 3, 4, 5]
  const num = Number(rate)
  const numRound = Math.round(Number(rate))

  return (
    <div className="star-rating">
      {arr.map((item) => {
        if (item <= num) {
          return <BsStarFill className="filled" key={item} />
        } else if (item === numRound) {
          return <BsStarHalf className="filled" key={item} />
        } else {
          return <BsStar key={item} />
        }
      })}
    </div>
  )
}

export default StarRating