import React, {useEffect, useState} from 'react'
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import PortfolioCard from "../components/portfolioCard";
import {useGetAllPortfoliosQuery} from "../store/api/portfolio";
import {IPortfolio} from "../@types";
import {GetAllPortfolios} from "../services/portfolio";

// --------------------------------------------------------------------------------
const Portfolio: React.FC = () => {
    const [portfolios, setPortfolios]= useState<IPortfolio[]>()
    useEffect(()=>{
        GetAllPortfolios().then(res=>setPortfolios(res))
    }, [])

  return (
      <main>
          <Container className='mt-4'>
              <Row className='gx-xxxl-5'>
                  <section className='sec-10 mb-5'>
                      <div className="title mb-4 mb-sm-5">
                          <h1 className="h2">Примеры работ</h1>
                      </div>
                  </section>
                  <section className='sec-8 mb-md-5'>
                      {portfolios?.map((element, index)=>
                          <PortfolioCard key={index} {...element}/>
                      )}
                  </section>

              </Row>
          </Container>
      </main>
  )
}

export default Portfolio